import React, { useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { BsPlayFill } from "react-icons/bs";
import { IconList } from "./IconList";
import { CustomColorBox } from "../../constant/CustomColorBox";
import { List } from "./List";

function valuetext(value) {
  return `${value}`;
}

function Card({
  sound_details,
  setColorPatternType,
  setCustomColorPattern,
  setCanvasColorTransitionSpeed,
  setVibranceOptionMenu,
  setSaturationOptionMenu,
  setMinBrightnessOptionMenu,
  setMonochromacy,
  setFadeInTime
}) {
  const [lights, setlights] = useState(0);
  const [spread, setspread] = useState(0);
  const [rotationspeed, setrotationspeed] = useState(0);
  const [texture, settexture] = useState(0);

  const [vibrance, setVibrance] = useState(0);
  const [saturation, setSaturation] = useState(0);
  const [minbrightness, setMinBrightness] = useState(0);

  const [colortransitionspeed, setColorTransitionSpeed] = useState(0);
  const [timesmoothing, setTimeSmoothing] = useState(0);

  const handleSliderLightsChange = (event, newValue) => {
    setlights(newValue);
  };

  const handleSliderSpreadChange = (event, newValue) => {
    setspread(newValue);
  };

  const handleSliderRotationSpeedChange = (event, newValue) => {
    setrotationspeed(newValue);
  };

  const handleSliderTextureChange = (event, newValue) => {
    settexture(newValue);
  };

  const handleSliderVibranceChange = (event, newValue) => {
    setVibranceOptionMenu(newValue);
    setVibrance(newValue);
  };

  const handleSliderSaturationChange = (event, newValue) => {
    setSaturationOptionMenu(newValue);
    setSaturation(newValue);
  };

  const handleSliderMinBrghtnessChange = (event, newValue) => {
    setMinBrightnessOptionMenu(newValue);
    setMinBrightness(newValue);
  };

  const handleSliderColorTransitionSpeedChange = (event, newValue) => {
    setColorTransitionSpeed(newValue);
    setCanvasColorTransitionSpeed(colortransitionspeed);
  };

  const handleSliderTimeSmoothingChange = (event, newValue) => {
    setFadeInTime(newValue);
    setTimeSmoothing(newValue);
  };

  const handleMonochromacyChange = (event, newValue) => {
    setMonochromacy(newValue);
  }

  const [customColor, setCustomColor] = useState({
    A: [45, 71, 205],
    "A#": [219, 201, 200],
    B: [199, 194, 202],
    C: [180, 187, 204],
    "C#": [161, 180, 206],
    D: [142, 173, 208],
    "D#": [122, 167, 210],
    E: [103, 160, 212],
    F: [84, 153, 214],
    "F#": [65, 146, 216],
    G: [45, 139, 218],
    "G#": [26, 132, 220],
  });

  ///Custom Culor
  const [selectedBoxId, setSelectedBoxId] = useState();
  const [sketchPickerColor, setSketchPickerColor] = useState({
    r: 0,
    g: 1,
    b: 0,
  });
  const [boxColors, setBoxColors] = useState(CustomColorBox);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const handleColorChange = (color) => {
    setSketchPickerColor(color.rgb);
    const updatedBoxColors = boxColors.map((boxColor) => {
      if (boxColor.id === selectedBoxId) {
        let newCustomColor = { ...customColor };
        newCustomColor[boxColor.id] = [color.rgb.r, color.rgb.g, color.rgb.b];
        setCustomColor(newCustomColor);

        return {
          ...boxColor,
          color: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
        };
      }
      return boxColor;
    });
    setBoxColors(updatedBoxColors);
  };
  const handleBoxColorClick = (id) => {
    setSelectedBoxId(id);
    setDisplayColorPicker((prevState) => !prevState);
  };

  const [value, setValue] = useState(10);

  const handleChange = (event, newValue) => {
    if (typeof newValue === "number") {
      setValue(newValue);
    }
  };

  const [currentListItem, setCurrentListItem] = useState("initial");
  const [activeIcon, setActiveIcon] = useState();
  const [title, setTitele] = useState("Sound Color Project");

  return (
    <div className="flex w-screen h-screen justify-center mt-20">
      <div className="">
        <div className="w-72 h-96 flex bg-white rounded-lg text-xs">
          <div className="flex flex-col bg-gray-300 ">
            {currentListItem != "initial" ? (
              <div
                className="w-10 p-4"
                onClick={() => {
                  setCurrentListItem("initial");
                  setTitele("Sound Color Project");
                  setActiveIcon();
                }}
              >
                <AiOutlineArrowLeft className="w-4 h-4" />
              </div>
            ) : (
              <div className="p-4 py-6"></div>
            )}
            {IconList.map((Icons, index) => (
              <div
                className={`w-10 p-4 ${
                  Icons.parentId === activeIcon && "bg-white"
                }`}
                key={index}
                onClick={() => {
                  setCurrentListItem(Icons.parentId);
                  setTitele(Icons.title);
                  setActiveIcon(Icons.parentId);
                }}
              >
                <div className="w-4 h-4">{Icons.Icon}</div>
              </div>
            ))}
          </div>
          <div className="flex flex-col w-full overflow-x-hidden h-96 overflow-y-auto">
            <div className=" flex justify-center font-bold py-4">{title}</div>
            {List(
              boxColors,
              handleBoxColorClick,
              handleColorChange,
              displayColorPicker,
              sketchPickerColor,
              setCustomColorPattern,
              setColorPatternType,
              handleSliderVibranceChange,
              handleSliderMinBrghtnessChange,
              valuetext,
              vibrance,
              saturation,
              minbrightness,
              lights,
              handleSliderLightsChange,
              handleSliderSpreadChange,
              spread,
              rotationspeed,
              handleSliderRotationSpeedChange,
              handleSliderTextureChange,
              texture,
              handleSliderColorTransitionSpeedChange,
              colortransitionspeed,
              handleSliderTimeSmoothingChange,
              timesmoothing,
              sound_details,
              handleSliderSaturationChange,
              customColor,
              handleMonochromacyChange,
            ).map((item, index) => {
              if (item.parentId === currentListItem) {
                return (
                  <div key={index}>
                    {item.name ? (
                      <div
                        className="flex relative"
                        onClick={() => {
                          if (item.isChild) {
                            setCurrentListItem(item.id);
                            setTitele(item.name);
                            currentListItem === "initial" &&
                              setActiveIcon(item.id);
                          } else if (
                            item.parentId === "color" &&
                            item.name != "custom"
                          ) {
                            setColorPatternType(item.name);
                          }
                        }}
                      >
                        <div
                          className={`p-2 flex-1  m-2 ${
                            item.isChild ||
                            (item.parentId === "color" &&
                              item.name != "custom" &&
                              "hover:bg-black hover:text-white rounded-lg")
                          } `}
                        >
                          {item.name}

                          {item.isChild && (
                            <div className="absolute top-4 left-52 ">
                              <BsPlayFill className="w-4 h-4" />
                            </div>
                          )}
                          {item.value && (
                            <div className="absolute top-4 left-40">
                              {item.value}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>{item.element}</div>
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
