import React from "react";
import { MdOutlineColorLens } from "react-icons/md";
import { BsFillPlugFill, BsThreeDots } from "react-icons/bs";
import { IoIosOptions } from "react-icons/io";
import { HiOutlineMusicNote } from "react-icons/hi";
export const IconList = [
  {
    Icon: <MdOutlineColorLens />,
    parentId: "color",
    title: "Color Patterns",
  },
  {
    Icon: <BsFillPlugFill />,
    parentId: "connections",
    title: "Connections",
  },

  {
    Icon: <IoIosOptions />,
    parentId: "options",
    title: "Options",
  },
  {
    Icon: <HiOutlineMusicNote />,
    parentId: "details",
    title: "Sound Details",
  },
  {
    Icon: <BsThreeDots />,
    parentId: "actions",
    title: "Actions",
  },
];
