import React, { useEffect, useRef } from "react";

const AudioVisualizer = ({ setPrimaryNode, setSoundDetails }) => {
  const canvasRef = useRef(null);
  const dataArray = new Uint8Array(64);

  useEffect(() => {
    const audioContext = new AudioContext();
    const analyserNode = audioContext.createAnalyser();
    const canvasCtx = canvasRef.current.getContext("2d");
    // canvasCtx.globalCompositeOperation = "destination-out";

    // Helper function to convert MIDI note number to note name
    function midiNoteToNoteName(midiNote) {
      var noteNames = [
        "C",
        "C#",
        "D",
        "D#",
        "E",
        "F",
        "F#",
        "G",
        "G#",
        "A",
        "A#",
        "B",
      ];
      var octave = Math.floor(midiNote / 12) - 1;
      var noteIndex = midiNote % 12;
      return noteNames[noteIndex] + octave.toString();
    }

    //   navigator.mediaDevices.enumerateDevices()
    // .then((devices) => {
    //   const inputDevices = devices.filter(device => device.kind === 'audioinput');
    //   console.log('Connected input devices:', inputDevices);

    //   const outputDevices = devices.filter(device => device.kind === 'audiooutput');
    //   console.log('Connected output devices:', outputDevices);
    // })
    // .catch((error) => {
    //   console.error('Error listing media devices:', error);
    // });

    // navigator.mediaDevices
    //   .enumerateDevices()
    //   .then((devices) => {
    //     const inputDevices = devices.filter(
    //       (device) => device.kind === "audioinput"
    //     );
    //     console.log("Connected input devices:", inputDevices);

    //     const audioInputDeviceList = inputDevices.map((device) => ({
    //       id: device.deviceId,
    //       parentId: "audio_source",
    //       name: device.label,
    //       isChild: false,
    //     }));

    //     console.log(audioInputDeviceList);

    //     const outputDevices = devices.filter(
    //       (device) => device.kind === "audiooutput"
    //     );
    //     console.log("Connected output devices:", outputDevices);
    //   })
    //   .catch((error) => {
    //     console.error("Error listing media devices:", error);
    //   });

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const sourceNode = audioContext.createMediaStreamSource(stream);
        sourceNode.connect(analyserNode);
        analyserNode.connect(audioContext.destination);
        // Disconnect the analyserNode from the AudioContext's destination node
        analyserNode.disconnect(audioContext.destination);

        draw(canvasCtx, analyserNode);

        // Get the frequency data and time-domain data
        var frequencyData = new Uint8Array(analyserNode.frequencyBinCount);
        var timeDomainData = new Float32Array(analyserNode.fftSize);

        // Calculate the reference value
        var referenceValue = Math.sqrt(0.1); // Use 0.1 as a reference value

        // Get the primary frequency and volume every 100 milliseconds
        setInterval(function () {
          var sound_details_all = [];
          analyserNode.getByteFrequencyData(frequencyData);
          analyserNode.getFloatTimeDomainData(timeDomainData);

          // Find the index of the frequency with the highest amplitude
          var maxFrequencyIndex = 0;
          var maxAmplitude = -Infinity;
          for (var i = 0; i < frequencyData.length; i++) {
            if (frequencyData[i] > maxAmplitude) {
              maxFrequencyIndex = i;
              maxAmplitude = frequencyData[i];
            }
          }

          // Calculate the primary frequency
          var frequency =
            (maxFrequencyIndex * audioContext.sampleRate) /
            analyserNode.fftSize;

          // Calculate the RMS value of the audio signal
          var rms = 0;
          for (var i = 0; i < timeDomainData.length; i++) {
            rms += timeDomainData[i] * timeDomainData[i];
          }
          rms = Math.sqrt(rms / timeDomainData.length);
          // Subtract the reference value from the RMS value to get the noise volume
          var noiseVolume = Math.max(0, rms - referenceValue);
          sound_details_all.push(noiseVolume.toFixed(2));
          sound_details_all.push(rms.toFixed(2));
          sound_details_all.push(frequency.toFixed(2));

          // Find the two peak frequency indices
          var peakFrequencyIndices = [];
          for (var i = 0; i < frequencyData.length; i++) {
            if (peakFrequencyIndices.length < 2) {
              peakFrequencyIndices.push(i);
            } else {
              if (frequencyData[i] > frequencyData[peakFrequencyIndices[0]]) {
                peakFrequencyIndices[1] = peakFrequencyIndices[0];
                peakFrequencyIndices[0] = i;
              } else if (
                frequencyData[i] > frequencyData[peakFrequencyIndices[1]]
              ) {
                peakFrequencyIndices[1] = i;
              }
            }
          }

          // Calculate the two primary MIDI notes
          var midiNotes = [];
          for (var i = 0; i < 2; i++) {
            var frequency =
              (peakFrequencyIndices[i] * audioContext.sampleRate) /
              analyserNode.fftSize;
            var midiNote = 69 + 12 * Math.log2(frequency / 440);
            midiNotes.push(Math.round(midiNote));
          }

          var cents = Math.floor(1200 * Math.log2(frequency / 440));

          // Convert MIDI notes to note names
          var noteNames = midiNotes.map(midiNoteToNoteName);

          sound_details_all.push(noteNames[0]);
          sound_details_all.push(cents.toFixed(2));
          setSoundDetails(sound_details_all);
          setPrimaryNode(noteNames);
        }, 1000);
      })
      .catch((error) => {});

    const draw = (canvasCtx, analyserNode) => {
      requestAnimationFrame(() => draw(canvasCtx, analyserNode));
      analyserNode.getByteFrequencyData(dataArray);
      canvasCtx.clearRect(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );

      const barWidth = (canvasRef.current.width / dataArray.length) * 2.5;
      let barHeight;
      let x = 0;

      for (let i = 0; i < dataArray.length; i++) {
        barHeight = dataArray[i] / 2;
        // canvasCtx.fillStyle = `rgb(${barHeight + 100},50,50)`;
        canvasCtx.fillStyle = "#e7e7e3";
        canvasCtx.fillRect(
          x,
          canvasRef.current.height - barHeight,
          barWidth,
          barHeight
        );
        x += barWidth + 1;
      }
    };
  }, []);

  return <canvas ref={canvasRef} width={window.innerWidth} height={200} />;
};

export default AudioVisualizer;
