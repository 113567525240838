import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { SimplexNoise } from "simplex-noise";

const Canvas = styled.canvas`
  margin: 0;
  padding: 0;
  width: ${(props) => props.styledWidth}px;
  height: ${(props) => props.styledHeight}px;
  position: relative;
  top: 0;
  left: 0;
  z-index: 0;
`;

const lerp = (x, x1, x2, y1, y2) => y1 + (x - x1) * ((y2 - y1) / (x2 - x1));

const getPixel = (noise, time, palette, vibrance, saturation, minBrightness, monochromacy) => {
  const paletteEvolution = Math.sin(((time % 3600) / 10) * (Math.PI / 180));

  let rgb = [];

  for (let i = 0; i < 3; i++) {
    rgb.push(
      lerp(
        Math.abs(noise),
        0,
        1,
        lerp(paletteEvolution, -1, 1, palette[0][0][i], palette[1][0][i]),
        lerp(paletteEvolution, -1, 1, palette[0][1][i], palette[1][1][i])
      ) * vibrance
    );
  }

  const hsl = rgbToHsl(...rgb);
  hsl[1] *= saturation;

  // Ensure the brightness is above the minimum
  hsl[2] = Math.max(hsl[2], minBrightness);

  // Check if monochromacy mode is enabled
  if (monochromacy) {
    const luminance = rgb[0] * 0.299 + rgb[1] * 0.587 + rgb[2] * 0.114;
    rgb = [luminance, luminance, luminance];
  } else {
    rgb = hslToRgb(...hsl);
  }

  return rgb;
};


const rgbToHsl = (r, g, b) => {
  r /= 255, g /= 255, b /= 255;
  const max = Math.max(r, g, b), min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;

  if (max == min) {
    h = s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }
    h /= 6;
  }

  return [h, s, l];
};

const hslToRgb = (h, s, l) => {
  let r, g, b;

  if (s == 0) {
    r = g = b = l;
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    }

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
};


const ColourfulCanvas = ({
  width,
  height,
  palette,
  speed,
  scale,
  resolution,
  fadeInTime,
  animate,
  vibrance,
  saturation,
  minBrightness,
  monochromacy
}) => {
  const canvasRef = useRef();
  const tRef = useRef(0);
  const rafRef = useRef();
  const simplex = useRef(new SimplexNoise());

  
  
  useEffect(() => {
    const frame = () => {
      let framePalette = [
        [[], []],
        [[], []],
      ];

      if (tRef.current <= fadeInTime) {
        for (let i = 0; i < 2; i++) {
          for (let j = 0; j < 2; j++) {
            for (let k = 0; k < 3; k++) {
              framePalette[i][j][k] = lerp(
                tRef.current,
                0,
                fadeInTime,
                0,
                palette[i][j][k]
              );
            }
          }
        }
      } else {
        framePalette = [...palette];
      }

      const ctx = canvasRef.current.getContext("2d");
      const imageData = ctx.createImageData(resolution, resolution);

      for (let x = 0; x < resolution; x++) {
        for (let y = 0; y < resolution; y++) {
          const i = (x + y * resolution) * 4;
          const noise = simplex.current.noise3D(
            x / scale,
            y / scale,
            tRef.current / (1000 / speed)
          );
          const pixel = getPixel(noise, tRef.current, framePalette, vibrance, saturation, minBrightness, monochromacy);
          imageData.data[i] = pixel[0];
          imageData.data[i + 1] = pixel[1];
          imageData.data[i + 2] = pixel[2];
          imageData.data[i + 3] = 255;
        }
      }

      ctx.putImageData(imageData, 0, 0);
      tRef.current++;
      rafRef.current = requestAnimationFrame(frame);
    };

    if (animate) {
      rafRef.current = requestAnimationFrame(frame);
    } else {
      cancelAnimationFrame(rafRef.current);
    }

    return () => cancelAnimationFrame(rafRef.current);
  }, [simplex, palette, speed, scale, resolution, fadeInTime, animate]);

  return (
    <React.Fragment>
      <Canvas
        ref={canvasRef}
        styledWidth={window.innerWidth}
        styledHeight={window.innerHeight}
        width={`${resolution}px`}
        height={`${resolution}px`}
      />
    </React.Fragment>
  );
};

export default ColourfulCanvas;
