export const ColorPatterns = {
  Chakras: {
    A: [128, 128, 128],
    "A#": [192, 192, 192],
    B: [255, 215, 0],
    C: [255, 0, 0],
    "C#": [255, 165, 0],
    D: [255, 255, 0],
    "D#": [0, 128, 0],
    E: [0, 0, 255],
    F: [75, 0, 130],
    "F#": [238, 130, 238],
    G: [255, 192, 203],
    "G#": [165, 42, 42],
  },
  Chromosthesia: {
    A: [128, 128, 128],
    "A#": [192, 192, 192],
    B: [255, 215, 0],
    C: [255, 0, 0],
    "C#": [255, 165, 0],
    D: [255, 255, 0],
    "D#": [0, 128, 0],
    E: [0, 0, 255],
    F: [75, 0, 130],
    "F#": [238, 130, 238],
    G: [255, 192, 203],
    "G#": [165, 42, 42],
  },
  Emotion: {
    A: [255, 255, 255],
    "A#": [165, 42, 42],
    B: [0, 0, 0],
    C: [255, 0, 0],
    "C#": [255, 165, 0],
    D: [255, 255, 0],
    "D#": [0, 255, 0],
    E: [0, 0, 255],
    F: [75, 0, 130],
    "F#": [238, 130, 238],
    G: [255, 192, 203],
    "G#": [128, 128, 128],
  },
  Chromotherapy: {
    A: [0, 128, 0],
    "A#": [192, 192, 192],
    B: [255, 215, 0],
    C: [255, 0, 0],
    "C#": [255, 69, 0],
    D: [255, 255, 0],
    "D#": [0, 128, 0],
    E: [0, 0, 255],
    F: [75, 0, 130],
    "F#": [238, 130, 238],
    G: [255, 192, 203],
    "G#": [165, 42, 42],
  },
  Adolescence: {
    A: [255, 228, 181],
    "A#": [250, 128, 114],
    B: [64, 224, 208],
    C: [176, 224, 230],
    "C#": [255, 182, 193],
    D: [230, 230, 250],
    "D#": [255, 255, 224],
    E: [255, 127, 80],
    F: [216, 191, 216],
    "F#": [152, 251, 152],
    G: [211, 211, 211],
    "G#": [173, 216, 230],
  },
  Custom: {
    A: [128, 128, 128],
    "A#": [192, 192, 192],
    B: [255, 215, 0],
    C: [255, 0, 0],
    "C#": [255, 165, 0],
    D: [255, 255, 0],
    "D#": [0, 128, 0],
    E: [0, 0, 255],
    F: [75, 0, 130],
    "F#": [238, 130, 238],
    G: [255, 192, 203],
    "G#": [165, 42, 42],
  },
};
