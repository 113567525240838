import React, { Component } from "react";
import MainScreen from "./pages/mainScreen";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audio: null,
    };
    this.toggleMicrophone = this.toggleMicrophone.bind(this);
  }

  async getMicrophone() {
    const audio = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
    this.setState({ audio });
  }

  stopMicrophone() {
    this.state.audio.getTracks().forEach((track) => track.stop());
    this.setState({ audio: null });
  }

  toggleMicrophone() {
    if (this.state.audio) {
      this.stopMicrophone();
    } else {
      this.getMicrophone();
    }
  }

  render() {
    return (
      <div>
        {/* <div className="controls">
          <button onClick={this.toggleMicrophone}>
            {this.state.audio ? "Stop microphone" : "Get microphone input"}
          </button>
        </div> */}
        <MainScreen />
        {/* <Background /> */}
        {/* <MIDI/> */}
        {/* {this.state.audio ? <AudioVisualiser /> : ""} */}
        {/* {this.state.audio ? <AudioAnalyser audio={this.state.audio} /> : ""} */}
      </div>
    );
  }
}

export default App;
