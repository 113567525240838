import React from "react";
import { Manager, Reference, Popper } from "react-popper";
import { Box, Typography, Stack } from "@mui/material";
import { SketchPicker } from "react-color";
import Slider from "@mui/material/Slider";
import Switch from "@mui/material/Switch";

export const List = (
  boxColors,
  handleBoxColorClick,
  handleColorChange,
  displayColorPicker,
  sketchPickerColor,
  setCustomColorPattern,
  setColorPatternType,
  handleSliderVibranceChange,
  handleSliderMinBrghtnessChange,
  valuetext,
  vibrance,
  saturation,
  minbrightness,
  lights,
  handleSliderLightsChange,
  handleSliderSpreadChange,
  spread,
  rotationspeed,
  handleSliderRotationSpeedChange,
  handleSliderTextureChange,
  texture,
  handleSliderColorTransitionSpeedChange,
  colortransitionspeed,
  handleSliderTimeSmoothingChange,
  timesmoothing,
  sound_details,
  handleSliderSaturationChange,
  customColor,
  handleMonochromacyChange
) => {
  return [
    {
      id: "color",
      parentId: "initial",
      name: "Color Patterns",
      isChild: true,
    },
    {
      id: "options",
      parentId: "initial",
      name: "Options",
      isChild: true,
    },
    {
      id: "connections",
      parentId: "initial",
      name: "Connections",
      isChild: true,
    },
    {
      id: "details",
      parentId: "initial",
      name: "Sound Details",
      isChild: true,
    },
    {
      id: "actions",
      parentId: "initial",
      name: "Actions",
      isChild: true,
    },
    {
      id: "custom",
      parentId: "color",
      name: "Custom",
      isChild: true,
    },
    {
      id: "custom",
      parentId: "custom",
      element: (
        <div>
          <div>
            <Manager>
              <div className="grid grid-cols-4 gap-4 px-4">
                {boxColors.map((boxColor) => (
                  <Reference key={boxColor.id}>
                    {({ ref }) => (
                      <div
                        ref={ref}
                        style={{
                          backgroundColor: boxColor.color,
                          width: 50,
                          height: 50,
                          borderRadius: 50,
                          border: "2px solid white",
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          marginTop: 20,
                        }}
                        onClick={() => handleBoxColorClick(boxColor.id)}
                      >
                        {boxColor.id}
                      </div>
                    )}
                  </Reference>
                ))}
                {displayColorPicker && (
                  <Popper placement="bottom">
                    {({ ref, style, placement }) => (
                      <div ref={ref} style={style} data-placement={placement}>
                        <SketchPicker
                          className="picker"
                          color={sketchPickerColor}
                          onChange={(color) => handleColorChange(color)}
                        />
                      </div>
                    )}
                  </Popper>
                )}
              </div>
            </Manager>
          </div>
          <div className="flex justify-center">
            <button
              className="bg-blue-500 px-4 py-1 rounded-lg mt-8 text-white text-xs"
              onClick={() => {
                setCustomColorPattern(customColor);
                setColorPatternType("custom");
              }}
            >
              Save
            </button>
          </div>
        </div>
      ),
      isChild: true,
    },
    {
      id: "chakras",
      parentId: "color",
      name: "Chakras",
      isChild: false,
    },
    {
      id: "chromosthesia",
      parentId: "color",
      name: "Chromosthesia",
      isChild: false,
    },
    {
      id: "emotion",
      parentId: "color",
      name: "Emotion",
      isChild: false,
    },
    {
      id: "adolescence",
      parentId: "color",
      name: "Adolescence",
      isChild: false,
    },
    {
      id: "chromotherapy",
      parentId: "color",
      name: "Chromotherapy",
      isChild: false,
    },
    {
      id: "audio_source",
      parentId: "connections",
      name: "Audio Source",
      isChild: true,
    },
    {
      id: "default_microphone_array",
      parentId: "audio_source",
      name: "Default-Microphone Array",
      isChild: false,
    },
    {
      id: "communications_microphone",
      parentId: "audio_source",
      name: "Communications Microphone Array",
      isChild: false,
    },
    {
      id: "microphone",
      parentId: "audio_source",
      name: "Microphone Array",
      isChild: false,
    },

    {
      id: "color_options",
      parentId: "options",
      name: "Color",
      isChild: true,
    },
    {
      id: "vibrance",
      parentId: "color_options",
      name: "Vibrance",
      isChild: false,
    },
    {
      id: "vibrance",
      parentId: "color_options",
      element: (
        <div className="flex justify-center">
          <Box sx={{ width: 180 }}>
            <Slider
              defaultValue={1}
              getAriaValueText={valuetext}
              valueLabelDisplay="auto"
              step={1.0}
              marks
              min={0}
              max={5.0}
              onChange={handleSliderVibranceChange}
            />
          </Box>
        </div>
      ),
      isChild: false,
    },
    {
      id: "saturation",
      parentId: "color_options",
      name: "Saturation",
      isChild: false,
    },
    {
      id: "saturation",
      parentId: "color_options",
      element: (
        <div className="flex justify-center">
          <Box sx={{ width: 180 }}>
            <Slider
              defaultValue={1}
              getAriaValueText={valuetext}
              valueLabelDisplay="auto"
              step={1}
              marks
              min={0}
              max={10}
              onChange={handleSliderSaturationChange}
            />
          </Box>
        </div>
      ),
      isChild: false,
    },
    {
      id: "minBrightness",
      parentId: "color_options",
      name: "Minimum Brightness",
      isChild: false,
    },
    {
      id: "minBrightness",
      parentId: "color_options",
      element: (
        <div className="flex justify-center">
          <Box sx={{ width: 180 }}>
            <Slider
              defaultValue={0}
              getAriaValueText={valuetext}
              valueLabelDisplay="auto"
              step={0.1}
              marks
              min={0}
              max={1.0}
              onChange={handleSliderMinBrghtnessChange}
            />
          </Box>
        </div>
      ),
      isChild: false,
    },
    {
      id: "monochromacy",
      parentId: "color_options",
      name: "Monochromacy",
      isChild: false,
    },
    {
      id: "monochromacy",
      parentId: "color_options",
      element: (
        <div className="flex justify-center">
          <Box sx={{ width: 180 }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <div className="text-xs">Off</div>
              <Switch onChange={handleMonochromacyChange} />
              <div className="text-xs">On</div>
            </Stack>
          </Box>
        </div>
      ),
      isChild: false,
    },
    // {
    //   id: "visualization",
    //   parentId: "options",
    //   name: "Visualization",
    //   isChild: true,
    // },
    {
      id: "visualization",
      parentId: "visualization",
      name: "Lights",
      isChild: false,
    },
    {
      id: "visualization",
      parentId: "visualization",
      element: (
        <div className="flex justify-center">
          <Box sx={{ width: 180 }}>
            <Slider
              value={lights}
              getAriaValueText={valuetext}
              valueLabelDisplay="auto"
              step={1}
              marks
              min={0}
              max={5}
              onChange={handleSliderLightsChange}
            />
          </Box>
        </div>
      ),
      isChild: false,
    },
    {
      id: "visualization",
      parentId: "visualization",
      name: "Spread",
      isChild: false,
    },
    {
      id: "visualization",
      parentId: "visualization",
      element: (
        <div className="flex justify-center">
          <Box sx={{ width: 180 }}>
            <Slider
              value={spread}
              getAriaValueText={valuetext}
              valueLabelDisplay="auto"
              step={0.1}
              marks
              min={0}
              max={1.0}
              onChange={handleSliderSpreadChange}
            />
          </Box>
        </div>
      ),
      isChild: false,
    },
    {
      id: "visualization",
      parentId: "visualization",
      name: "Rotation speed",
      isChild: false,
    },
    {
      id: "visualization",
      parentId: "visualization",
      element: (
        <div className="flex justify-center">
          <Box sx={{ width: 180 }}>
            <Slider
              value={rotationspeed}
              getAriaValueText={valuetext}
              valueLabelDisplay="auto"
              step={0.1}
              marks
              min={0}
              max={1.0}
              onChange={handleSliderRotationSpeedChange}
            />
          </Box>
        </div>
      ),
      isChild: false,
    },
    {
      id: "visualization",
      parentId: "visualization",
      name: "Texture",
      isChild: false,
    },
    {
      id: "visualization",
      parentId: "visualization",
      element: (
        <div className="flex sm:flex-shrink justify-center">
          <Box sx={{ width: 180 }}>
            <Slider
              value={texture}
              getAriaValueText={valuetext}
              valueLabelDisplay="auto"
              step={0.1}
              marks
              min={0}
              max={1.0}
              onChange={handleSliderTextureChange}
            />
          </Box>
        </div>
      ),
      isChild: false,
    },
    {
      id: "timing",
      parentId: "options",
      name: "Timing",
      isChild: true,
    },
    {
      id: "colortransitionspeed",
      parentId: "timing",
      name: "Color Transition Speed",
      isChild: false,
    },
    {
      id: "colortransitionspeed",
      parentId: "timing",
      element: (
        <div className="flex justify-center">
          <Box sx={{ width: 180 }}>
            <Slider
              value={colortransitionspeed}
              getAriaValueText={valuetext}
              valueLabelDisplay="auto"
              marks
              min={0}
              max={100}
              onChange={handleSliderColorTransitionSpeedChange}
            />
          </Box>
        </div>
      ),
      isChild: false,
    },
    {
      id: "timesmoothing",
      parentId: "timing",
      name: "Fade In",
      isChild: false,
    },
    {
      id: "timesmoothing",
      parentId: "timing",
      element: (
        <div className="flex justify-center">
          <Box sx={{ width: 180 }}>
            <Slider
              defaultValue={150}
              getAriaValueText={valuetext}
              valueLabelDisplay="auto"
              step={10}
              marks
              min={0}
              max={150}
              onChange={handleSliderTimeSmoothingChange}
            />
          </Box>
        </div>
      ),
      isChild: false,
    },
    {
      id: "noise_volume",
      parentId: "details",
      name: "Noise volume",
      value: sound_details[0] + " db",
      isChild: false,
    },
    {
      id: "primary_tone_volume",
      parentId: "details",
      name: "Primary Tone Volume",
      value: sound_details[1] + " db",
      isChild: false,
    },
    {
      id: "primary_frequency",
      parentId: "details",
      name: "Primary Frequency",
      value: sound_details[2] + " Hz",
      isChild: false,
    },
    {
      id: "primary_note",
      parentId: "details",
      name: "Primary Note",
      value:
        sound_details[3] == "undefined-Infinity" ? "None" : sound_details[3],
      isChild: false,
    },
    {
      id: "cents",
      parentId: "details",
      name: "Cents ♭",
      value: sound_details[4],
      isChild: false,
    },
    {
      id: "install_sound_color_app",
      parentId: "actions",
      name: "Install Sound Color App",
      isChild: false,
    },
    {
      id: "take_screenshot",
      parentId: "actions",
      element: (
        <div class="flex relative">
          <div class="p-2 flex-1  m-2 hover:bg-black hover:text-white rounded-lg ">
            Take a screenshot
          </div>
        </div>
      ),
      isChild: false,
    },
  ];
};
