export const CustomColorBox = [
  { id: "A", color: "rgb(45, 71, 205)" },
  { id: "A#", color: "rgb(219, 201, 200)" },
  { id: "B", color: "rgb(199, 194, 202)" },
  { id: "C", color: "rgb(180, 187, 204)" },
  { id: "C#", color: "rgb(161, 180, 206)" },
  { id: "D", color: "rgb(142, 173, 208)" },
  { id: "D#", color: "rgb(122, 167, 210)" },
  { id: "E", color: "rgb(103, 160, 212)" },
  { id: "F", color: "rgb(84, 153, 214)" },
  { id: "F#", color: "rgb(65, 146, 216)" },
  { id: "G", color: "rgb(45, 139, 218)" },
  { id: "G#", color: "rgb(26, 132, 220)" },
];
