import React from "react";
import ColourPattern from "../components/visualizer";
const MainScreen = () => {
  return (
    <div className="w-screen h-screen fixed">
      <ColourPattern />
    </div>
  );
};

export default MainScreen;
