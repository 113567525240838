import React, { useLayoutEffect, useState, useRef } from "react";
import ColourfulCanvas from "../ColourfulCanvas";
import Card from "../Card";
import AudioVisualiser from "../Waveform";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { ColorPatterns } from "../../constant/colorPatters";

const ColourPattern = () => {
  const [primaryNaode, setPrimaryNode] = useState(["F7", "F#7"]);
  const [colorPatternType, setColorPatternType] = useState("Chakras");
  const [customColor, setCustomColorPattern] = useState();
  const [sound_detail, setSoundDetails] = useState([0, 0, 0, 0]);
  const [showCard, setShowCard] = useState(true);
  const [palette, setColorPalette] = useState([
    [
      [0, 0, 0],
      [255, 255, 255],
    ],
    [
      [0, 0, 0],
      [255, 255, 255],
    ],
  ]);

  const [colortransitionspeed, setCanvasColorTransitionSpeed] = useState(0);
  const [fadeinTime, setFadeInTime] = useState(150);
  const [vibrance, setVibranceOptionMenu] = useState(1);
  const [saturation, setSaturationOptionMenu] = useState(1);
  const [minBrightness, setMinBrightnessOptionMenu] = useState(0);
  const [monochromacy, setMonochromacy] = useState(false);

  //set color palette for the node
  useLayoutEffect(() => {
    let newPalette = [];
    if (colorPatternType === "custom") {
      ColorPatterns["custom"] = { ...customColor };
    }

    primaryNaode[0] === "undefined-Infinity"
      ? newPalette.push([0, 0, 0])
      : newPalette.push(
          ColorPatterns[colorPatternType][
            primaryNaode[0].substring(0, primaryNaode[0].length - 1)
          ]
        );
    primaryNaode[1] === "undefined-Infinity"
      ? newPalette.push([255, 255, 255])
      : newPalette.push(
          ColorPatterns[colorPatternType][
            primaryNaode[1].substring(0, primaryNaode[1].length - 1)
          ]
        );
    setColorPalette([[...newPalette], [...newPalette]]);
  }, [primaryNaode, colorPatternType]);

  const toggleCard = () => {
    setShowCard(!showCard);
  };

  return (
    <div className="flex relative ">
      <ColourfulCanvas
        palette={palette}
        speed={colortransitionspeed}
        scale={100}
        resolution={50}
        fadeInTime={fadeinTime}
        animate={true}
        vibrance={vibrance}
        saturation={saturation}
        minBrightness={minBrightness}
        monochromacy={monochromacy}
      />
      <div className="absolute top-0 right-0">
        <button onClick={toggleCard} className="fixed top-4 right-4 z-50">
          {showCard ? <BsEyeSlashFill size={24} /> : <BsEyeFill size={24} />}
        </button>
        {showCard && (
          <Card
            sound_details={sound_detail}
            setColorPatternType={setColorPatternType}
            setCustomColorPattern={setCustomColorPattern}
            setCanvasColorTransitionSpeed={setCanvasColorTransitionSpeed}
            setFadeInTime={setFadeInTime}
            setVibranceOptionMenu={setVibranceOptionMenu}
            setSaturationOptionMenu={setSaturationOptionMenu}
            setMinBrightnessOptionMenu={setMinBrightnessOptionMenu}
            setMonochromacy={setMonochromacy}
          />
        )}
      </div>
      <div className="absolute bottom-0">
        <AudioVisualiser
          setPrimaryNode={setPrimaryNode}
          setSoundDetails={setSoundDetails}
        />
      </div>
    </div>
  );
};

export default ColourPattern;
